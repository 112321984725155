import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createMarkup, useResponsiveType } from '../../utils';
import tokens from './tokens';

// Interface

export interface HtmlProps {
  children?: any;
  small?: boolean;
  large?: boolean;
  narrow?: boolean;
  handleNestedSpacing?: boolean; // Space nested divs when set to true
}

export interface StyledHtmlProps {
  small?: boolean;
  large?: boolean;
  narrow?: boolean;
  handleNestedSpacing?: boolean;
}

// Styles

export const StyledHtml = styled.div<StyledHtmlProps>`
  ${({
    theme: { space, colors, borderRadii, maxMedia },
    small,
    large,
    narrow,
    handleNestedSpacing,
  }) => css`
    margin-left: auto;
    margin-right: auto;

    > * + * {
      margin-block-start: 1em;
    }

    ${narrow &&
    css`
      max-width: 736px;
    `}

    ${handleNestedSpacing &&
    css`
      > div {
        > * + * {
          margin-block-start: 1em;
        }
      }

      > div > div {
        > * + * {
          margin-block-start: 1em;
        }
      }

      > div > div > div {
        > * + * {
          margin-block-start: 1em;
        }
      }
    `}

    ol,
    ul {
      padding-left: ${space.medium};

      li + li {
        margin-top: ${space.small};
      }
    }

    // Links + exclude buttons

    a:not([href^='tel:']):not([variant='primary']):not([variant='secondary']) {
      color: ${colors[tokens.anchorColor]};

      &:hover {
        color: ${colors[tokens.anchorHoverColor]};
      }
    }

    // List styles

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: ${space.medium};
        &:before {
          content: '';
          position: absolute;
          top: ${space.xxSmall};
          left: 0;
          width: 8px;
          height: 8px;
          background-color: ${colors[tokens.ulColor]};

          ${small &&
          css`
            top: 5px;
          `}
        }

        ul {
          margin-top: ${space.small};
        }
      }
    }

    ol {
      counter-reset: section;
      list-style-type: decimal;
      padding-left: 0;
      margin-left: ${space.small};

      li {
        counter-increment: section;
        position: relative;
        padding-left: ${space.xxSmall};
      }

      ol {
        padding-left: ${space.medium};
      }

      // Nested ol styles
      ol ol {
        padding-left: ${space.small};
      }
    }

    // Typography

    h1 {
      ${useResponsiveType('headingXLarge')}
    }

    h2 {
      ${useResponsiveType('headingMedium')}
    }

    h3 {
      ${useResponsiveType('headingSmall')}
    }

    h4,
    h5,
    h6 {
      ${useResponsiveType('bodyMediumBold')}
    }

    strong {
      ${useResponsiveType('bodyMediumBold')}
    }

    // Small theme

    ${small &&
    css`
      ${useResponsiveType('bodySmall')}

      h4,
    h5,
    h6 {
        ${useResponsiveType('bodySmallBold')}
      }

      strong {
        ${useResponsiveType('bodySmallBold')}
      }
    `}

    // Large theme

    ${large &&
    css`
      h2 {
        ${useResponsiveType('headingLarge')}
      }

      p {
        ${useResponsiveType('bodyLarge')}
      }
    `} 
    
    // table styles
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: ${space.medium};
      margin-bottom: ${space.medium};
      border-radius: ${borderRadii[tokens.tableBorderRadius]};
      border: 1px solid ${colors[tokens.tableBorderColor]};
      overflow-x: auto;
      display: block;

      th,
      td {
        padding: ${space.small};
        border: 1px solid ${colors[tokens.tableBorderColor]};
        text-align: left;

        &:first-of-type {
          border-left: 0;
        }

        &:last-of-type {
          border-right: 0;
        }
      }

      thead td {
        background-color: ${colors[tokens.thBackgroundColor]};
        border-left: 1px solid ${colors[tokens.thBackgroundColor]};
        border-right: 1px solid ${colors[tokens.thBackgroundColor]};

        &:first-of-type {
          border-left: 0;
        }

        &:last-of-type {
          border-right: 0;
        }
      }

      tbody tr:nth-of-type(even) {
        background-color: ${colors.utilityWhite};
      }

      tbody tr:nth-of-type(odd) {
        background-color: ${colors[tokens.oddRowBackgroundColor]};
      }

      tbody tr:last-of-type {
        td {
          border-bottom: 0;
        }
      }

      /* @media (max-width: ${maxMedia.medium}) {
         ${tokens.tableMobileStyles &&
      css`
            display: block;

            // Make thead invisible but keep for accessibility
            thead {
              border: none;
              clip: rect(0 0 0 0);
              height: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 1px;
            }

            // Convert rows to cards
            tr {
              border-bottom: ${space.xxSmall} solid ${colors[tokens.ulColor]};

              &:last-child {
                border-bottom: none;
              }
            }

            // Convert cells to vertical layout with labels
            td {
              display: block;
              text-align: left;
              padding: 0;
              border: none;

              // Add header labels using data-label
              &:before {
                content: attr(data-label);
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: ${space.xxSmall};
                background-color: ${colors[tokens.thBackgroundColor]};
                ${useResponsiveType('bodySmallBold')}
                padding: ${space.xSmall};
              }

              &:last-child {
                padding-bottom: ${space.medium};
              }
                
              &:only-child {
                padding-bottom: 0;
              }

              // Handle nested lists in cells
              ul,
              ol {
                margin-top: ${space.xxSmall};
                margin-bottom: 0;
              }

              p,
              ul {
                padding: 0 ${space.xSmall} ${space.xSmall} ${space.xSmall};
              }

              li {
                padding-left: ${space.xSmall};
              }
            }
          }
        `} */
    }
  `};
`;

// JSX

export const Html: FC<React.PropsWithChildren<HtmlProps>> = ({
  children,
  small,
  large,
  narrow,
  handleNestedSpacing,
  ...rest
}) => (
  <StyledHtml
    small={small}
    large={large}
    {...rest}
    narrow={narrow}
    handleNestedSpacing={handleNestedSpacing}
  >
    {typeof children === 'string' ? createMarkup(children) : children}
  </StyledHtml>
);

export default Html;
